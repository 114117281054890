input[type=button], input[type=submit] {
  background-color: $tertiary;
  border: none;
  color: $header-text-color;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 5px;
  transition: all 0.3s ease;

  &:hover {
    background-color: lighten($color: $tertiary, $amount: 8);
  }
}

input[type=text] {
  position: relative;
  border: 1px solid $header-bg-color;
  background-color: $header-text-color;
  border-radius: 3px;
  width: 100%;
  padding: 6px;

  &:focus {
    border-color: $tertiary;
  }
}

textarea {
  position: relative;
  border: 1px solid $header-bg-color;
  background-color: $header-text-color;
  border-radius: 3px;
  width: 100%;
  padding: 6px;

  &:focus {
    border-color: $tertiary;
  }
}

.error-text {
  color: darken($color: red, $amount: 5);
}