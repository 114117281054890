.articles {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  article {
    border-style: solid;
    border-width: 1px;
    border-radius: $border-radius;
    padding: 0px;
    flex: 0 0 33.333%;
    box-shadow: 6px 6px 3px darken($color: $section-bg-color, $amount: 8);
    margin: 12px;
    max-width: 350px;
    min-width: 350px;
    background-color: $header-text-color;
    font-size: 90%;
    transition: all 0.3s ease;

    &:hover {
      box-shadow: 6px 6px 3px darken($color: $section-bg-color, $amount: 30);
    }

    header {
      border-radius: $inner-border-radius $inner-border-radius 0px 0px;
      padding: 6px;
      text-align: center;
      width: 100%;
      height: 36px;

      a {
        color: $header-text-color;
        text-decoration: none;
        transition: all 0.3s ease;

        &:hover {
          text-decoration: underline;
        }
      }

      img {
        -webkit-filter: invert(80%);
        /* safari 6.0 - 9.0 */
        filter: invert(80%);
        height: 24px;
        transition: all 0.3s ease;

        &:hover {
          -webkit-filter: invert(100%);
          /* safari 6.0 - 9.0 */
          filter: invert(100%);
        }
      }
    }
  }

  p {
    text-align: left;
    padding: 6px;
  }

  img {
    width: 100%;
  }
}

/* Tablets */
@media screen and (max-width: 992px) {
  article {
    flex: 0 0 50%;
  }
}

/* Large smartphones */
@media screen and (max-width: 768px) {
  article {
    flex: 0 0 100%;
    margin: 6px 2px 6px 2px;
  }
}

/* Small smartphones  */
@media screen and (max-width: 576px) {
  article {
    min-width: none;
  }
}